<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/pos-order-online-list"
      api-download-url="/pos-order-online-export"
      download-file-name="don-hang-pos.xlsx"
      @orderCancel="orderCancel"
    ></custom-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  DELIVERY_STATE,
  GOODS_ISSUE_STATE,
  STAMP_STATUS,
  CUSTOM_VIEW_POS_ORDER_ONLINE,
} from "@/libs/const";
import moment from "moment";

export default {
  name: "Index",
  data: () => ({
    customView: CUSTOM_VIEW_POS_ORDER_ONLINE,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    statusOptions: [...GOODS_ISSUE_STATE],
    deliveryStateOption: [...DELIVERY_STATE],
    stampStatus: [...STAMP_STATUS],
  }),

  created() {
    const columns = [
      {
        type: "select-pos",
        label: this.$t("labels.pos"),
        placeholder: this.$t("labels.pos"),
        name: "id_pos",
        hasSort: true,
        sortName: "pos_code",
        key: "pos_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: true,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        component: "OrderTracking",
        buttonActions: [
          {
            action: "orderCancel",
            text: this.$t("labels.cancel"),
            color: "error",
            permissions: [],
            modules: [],
            allowKey: "can_cancel",
          },
        ],
      },
      {
        type: "input-filter",
        label: this.$t("labels.customer_order_id"),
        placeholder: this.$t("labels.customer_order_id"),
        name: "customer_order_id",
        hasSort: true,
        sortName: "customer_order_id",
        key: "customer_order_id",
      },
      {
        type: "input-filter",
        label: this.$t("labels.delivery_order_id_1"),
        placeholder: this.$t("labels.delivery_order_id_1"),
        name: "delivery_order_id",
        hasSort: true,
        sortName: "delivery_order_id",
        key: "delivery_order_id",
        component: "DeliveryTracking",
      },
      {
        type: "select-delivery-company",
        label: this.$t("labels.delivery_company"),
        placeholder: this.$t("labels.delivery_company"),
        name: "id_delivery_company",
        hasSort: true,
        sortName: "delivery_company_name",
        key: "delivery_company_name",
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time_2"),
        placeholder: this.$t("labels.create_time_2"),
        name: "create_time",
        hasSort: true,
        sortName: "create_time",
        key: "create_time",
        required: true,
        defaultValue: [
          moment().subtract("10", "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.finish_delivery_time"),
        placeholder: this.$t("labels.finish_delivery_time"),
        name: "finish_delivery_time",
        hasSort: true,
        sortName: "finish_delivery_time",
        key: "finish_delivery_time",
      },
      {
        type: "select-filter",
        label: this.$t("labels.warehouse_status"),
        placeholder: this.$t("labels.warehouse_status"),
        name: "state",
        hasSort: true,
        sortName: "goods_issue_state_name",
        key: "goods_issue_state_name",
        required: true,
        options: this.statusOptions,
      },
      {
        type: "input-filter",
        label: this.$t("labels.delivery_status"),
        placeholder: this.$t("labels.delivery_status"),
        name: "delivery_partner_state",
        hasSort: true,
        sortName: "delivery_partner_state",
        key: "delivery_partner_state",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.summary_status"),
        placeholder: this.$t("labels.summary_status"),
        name: "id_delivery_state",
        hasSort: true,
        sortName: "delivery_state_name",
        key: "delivery_state_name",
        required: true,
        options: this.deliveryStateOption,
      },
      {
        type: "input-filter",
        label: this.$t("labels.export"),
        placeholder: this.$t("labels.export"),
        name: "barcode",
        hasSort: true,
        sortName: "barcode",
        key: "item_html",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.receiver"),
        placeholder: this.$t("labels.receiver"),
        name: "receiver_name",
        hasSort: true,
        sortName: "receiver_name",
        key: "receiver_name",
      },
      {
        type: "input-filter",
        label: this.$t("labels.phone_number"),
        placeholder: this.$t("labels.phone_number"),
        name: "receiver_phone_number",
        hasSort: true,
        sortName: "receiver_phone_number",
        key: "receiver_phone_number",
      },
      {
        type: "input-filter",
        label: this.$t("labels.address"),
        placeholder: this.$t("labels.address"),
        name: "address",
        hasSort: true,
        sortName: "address",
        key: "address",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.cod"),
        placeholder: this.$t("labels.cod"),
        name: "cash_on_delivery",
        hasSort: true,
        sortName: "cash_on_delivery",
        key: "cash_on_delivery",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.money_collected"),
        placeholder: this.$t("labels.money_collected"),
        name: "money_collected",
        hasSort: true,
        sortName: "money_collected",
        key: "money_collected",
        required: true,
      },
      {
        type: "select-e-market",
        label: this.$t("labels.source"),
        placeholder: this.$t("labels.source"),
        class: "c-input-xs input-filter",
        name: "id_e_market",
        hasSort: true,
        sortName: "e_market_name",
        key: "e_market_name",
        params: {
          "is-filter": true,
        },
      },
      {
        type: "select-e-market-shop",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        class: "c-input-xs input-filter",
        name: "id_e_market_shop",
        hasSort: true,
        sortName: "customer_e_market_shop_name",
        key: "customer_e_market_shop_name",
        params: {
          "is-check-e-market": false,
        },
      },
      {
        type: "select-filter",
        label: this.$t("labels.stamp"),
        placeholder: this.$t("labels.stamp"),
        name: "stamp_file",
        hasSort: true,
        sortName: "stamp_file",
        key: "stamp_file",
        options: this.stampStatus,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "id_creator",
        hasSort: true,
        sortName: "creator_name",
        key: "creator_name",
      },
      {
        type: "",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        class: "c-red--text",
        name: "note",
        hasSort: true,
        sortName: "note",
        key: "note",
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [
      {
        text: this.$t("labels.order"),
        key: "sumOrder",
      },
    ];
    this.sums = [...sums];
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },

    async orderCancel(item) {
      if (!confirm("Bạn có chắc chắn muốn hủy đơn hàng này không?")) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/orders-cancel", {
          id_order: item.id,
        });
        this.isLoading = false;
        this.refreshCustomTable();
        this.$vToastify.warning(this.$t("messages.order_canceled_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
